import { cn } from "@/lib/utils"
import type { JSX } from "react"

interface Props {
    id?: string
    className?: string
    background?: string
    children: React.ReactNode
}

/* Only does x-padding. So no y-padding by default */
export default function Section({ id, className, background, children }: Props): JSX.Element {
    const classes = cn("mx-auto max-w-7xl px-4 md:px-8 lg:px-12", "dark:text-gray-100", className)

    if (background) {
        return (
            <div className={cn(background, "dark:bg-gray-900")}>
                <section id={id} className={classes}>
                    {children}
                </section>
            </div>
        )
    }

    return (
        <section id={id} className={classes}>
            {children}
        </section>
    )
}
