"use client"

import Link from "next/link"
import { useEffect } from "react"
import Section from "@/components/common/section"

export default function Error({ error, reset }: { error: Error & { digest?: string }; reset: () => void }) {
    useEffect(() => {
        // Log the error to an error reporting service
        console.error(error)
    }, [error])

    return (
        <Section className="min-h-full py-16 sm:py-24 md:grid md:place-items-center">
            <main className="sm:flex">
                <p className="text-4xl font-bold tracking-tight text-tce-600 sm:text-5xl">ERR</p>
                <div className="sm:ml-6">
                    <div className="sm:border-l sm:border-gray-300 sm:pl-6 bg-white rounded-lg p-4">
                        <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Error</h1>
                        <p className="mt-3 text-base text-gray-500">Things are not working.</p>
                        <p className="mt-4 text-red-700 font-mono text-sm">{error.message}</p>
                        <p className="mt-4 text-gray-500 font-mono text-sm">{error.digest}</p>
                    </div>
                    <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                        <Link
                            href="/"
                            className="inline-flex items-center rounded-md border border-transparent bg-tce-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-tce-700 focus:outline-none focus:ring-2 focus:ring-tce-500 focus:ring-offset-2"
                        >
                            Back to the homepage
                        </Link>
                        <Link
                            href="mailto:support@tce.exchange"
                            className="inline-flex items-center rounded-md border border-transparent bg-tce-100 px-4 py-2 text-sm font-medium text-tce-700 hover:bg-tce-200 focus:outline-none focus:ring-2 focus:ring-tce-500 focus:ring-offset-2"
                        >
                            Send e-mail to support
                        </Link>
                    </div>
                </div>
            </main>
        </Section>
    )
}
